.note-sms {
  background: #2f48d4;
}

.note-system {
  background: #111111;
}

.note-message {
  background: #2e3668;
}

.note-inbound-text {
  background: #ff482c;
}

.note-outbound-text {
  background: #ff6d56;
}

.note-inbound-call {
  background: #ffb109;
}

.note-outbound-call {
  background: #ffc13a;
}

.note-inbound-email {
  background: #00c04a;
}

.note-outbound-email {
  background: #33cd6e;
}

.note-internal {
  background: #292929;
}

.note-inperson-meeting {
  background: #2f9dd4;
}

.note-left-voicemail {
  background: #6319c4;
}

.zendesk-note {
  background: #17494d;
}

.note {
  text-align: center;
  margin: 7.5em 0;
}

.note-pill {
  margin-top: 0.5em;
  padding: 4px 8px;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  -ms-border-radius: 4em;
  border-radius: 4em;
  font-size: 0.8em;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: white;
}

.timeline {
  border-left: 3px solid #6e7071;
  margin: 50px auto;
  line-height: 1.2em;
  font-size: 1em;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 67%;
}
.timeline .notes-source {
  font-size: 1.1em;
  color: #1e202b;
}
.timeline .event {
  color: #6e7071;
  padding-bottom: 25px;
  margin-bottom: 15px;
  position: relative;
}
.timeline .event .note-body {
  position: relative;
  font-style: italic;
  padding: 0.75em;
  margin-top: 25px;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  -ms-border-radius: 0.5em;
  border-radius: 0.5em;
  background-color: rgba(0, 0, 0, 0.1);
  word-break: break-word;
}
.timeline .event .note-body:after {
  content: "";
  position: absolute;
  top: 0;
  left: 8%;
  width: 0;
  height: 0;
  border: 18px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  border-top: 0;
  margin-left: -18px;
  margin-top: -18px;
}
.timeline .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}
.timeline .event:before, .timeline .event:after {
  position: absolute;
  display: block;
  top: 0;
}
.timeline .event:before {
  left: -231px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}
.timeline .event:after {
  left: -61px;
  background: #f9f6f2;
  border: 3px solid #6e7071;
  -webkit-border-radius: 4em;
  -moz-border-radius: 4em;
  -ms-border-radius: 4em;
  border-radius: 4em;
  height: 20px;
  width: 20px;
  content: "";
  top: 2.5px;
}

