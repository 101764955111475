@import "./../Styles/mixins.scss";
@import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";

.asset {
  margin-bottom: $sm;
  .asset-container:hover {
    border: 2px solid #fff;
  }

  .asset-container {
    @include border(solid, 1px);
    @include borderRadius($xs);
    height: 500px;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    display: flex;

    .asset-img {
      margin: 0 auto;
      display: block;
    }
  }

  .slide {
    height: 500px;
    @include border(solid, 1px);
    @include borderRadius($xs);
  }

  .slider-wrapper {
    overflow: hidden;
    display: flex;
    width: inherit;
    @include border(solid, 1px);
    @include borderRadius($xs);
  }

  .img-carousel {
    width: 425px;
    transform: scale(1.01);
    display: flex;
  }
}
