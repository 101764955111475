@import './../Styles/mixins.scss';
.location-data {
  align-items: center;
  color: #2cff90;
  display: flex;
  flex-direction: column;
  font-size: $lg - 0.7em;
  justify-content: center;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 250px;

  .marker {
    transform: rotate(135deg);
    padding: $sm 0;
  }
}
