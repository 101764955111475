@import 'bulma/bulma.sass';
@import './Styles/mixins.scss';
@import './Styles/buttons.scss';
@import './Styles/tabs.scss';
@import './Styles/miniTable.scss';
@import './Styles/Forms.scss';
@import './Styles/dropdown.scss';
@import './Styles/toasts.scss';
@import '../node_modules/react-toastify/dist/ReactToastify.min.css';
@import '../node_modules/react-dual-listbox/lib/react-dual-listbox.css';

html {
  margin: 0px;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: $bg-light;
  @include fontLato();
  color: $darkGunmetal;
  box-sizing: border-box;
  overflow-x: hidden;
}

p,
.p-style {
  font-size: $rg;
  color: $darkGunmetal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include fontLato();
}

h1 {
  font-size: $lg - 0.05em;
  font-weight: bold;
}

h2 {
  font-size: $md;
}

h3 {
  font-size: $md - 0.25em;
}

.error-message {
  color: $red;
  font-size: $rg;
}

.hide {
  display: none !important;
}

.Toastify__toast-container {
  width: 400px;
  padding: $xs !important;
}

.Toastify__toast--error {
  background: $red;
  font-size: $rg;
  text-align: center;
  @include borderRadius($xs);
}

.Toastify__toast--success {
  background: rgba(0, 146, 69, 8);
  font-size: $rg;
  @include borderRadius($xs);
}

.__react_component_tooltip.type-dark {
  background: $darkGunmetal;
}

#siteContainer {
  margin: 0;
  padding: 0;
  *:focus {
    outline: none;
  }

  a {
    color: $dmGreen;

    &.child-links {
      color: $bg-light;
      margin: 2.5em;
    }
  }

  .item-label {
    color: $label;
  }

  // START Spacing Utilities ———————————————————————————— •

  .margin-lg {
    margin-bottom: $lg;
  }
  .marginRight {
    margin-right: 15px;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .centered-container {
    margin: auto;
    display: block;
  }

  .no-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin: 0;
    input {
      margin: 0;
    }
  }

  // START Map Styles ———————————————————————————— •
  .map-container {
    min-width: 300px;
    height: 325px;
    padding: 0;
    @include border(solid, 1px);
    @include borderRadius($xs);
    margin: $sm;
    overflow: hidden;
  }

  .map-container-btn {
    min-width: 300px;
    height: 325px;
    padding: 0;
    @include border(solid, 1px);
    @include borderRadius($xs);
    margin-bottom: $sm;
    overflow: hidden;
  }

  .performance-metrics {
    margin: $sm;
  }

  .single-page-actions {
    z-index: 0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px $bg-light inset;
    box-shadow: 0 0 0px 1000px $bg-light inset;
    -webkit-text-fill-color: $darkGunmetal;
  }

  // START Card Styles ———————————————————————————— •
  .card {
    margin: 1em;
    box-shadow: none;
    background: $bg-light;

    .card-image {
      overflow: hidden;
      @include borderRadius($xs);
      @include border(solid, 1px);
      min-height: 150px;
      background: url('./images/dm_placeholder.svg') no-repeat;
      background-size: 20%;
      background-position: center;

      img {
        display: flex;
      }
    }

    .card-image:hover {
      border-color: $dmGreen;
    }
  }

  // START Table Metadata Styles ———————————————————————————— •
  .metadata-container {
    margin-bottom: $md !important;
    text-align: center;
    h2 {
      font-weight: 700;
    }
  }

  // START Table Metadata Styles ———————————————————————————— •
  #footer {
    bottom: 0%;
    left: 45%;
    margin-top: $xl;
    p {
      font-size: $sm;
      text-align: center;
      padding: $sm;
    }
    .version-number {
      display: block;
    }
  }
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: $lg - 0.4em;
    font-weight: bold;
  }

  h2 {
    font-size: $md - 0.5em;
  }

  h3 {
    font-size: $md - 0.5em;
  }
}

.flatten {
  padding: 0.5em;
  cursor: pointer;
  background: none;
  border: none;
}
