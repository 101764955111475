.Toastify__toast-theme--dark {
  color: #000;
}
.Toastify__toast-theme--light {
  color: #000;
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  color: #000;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  color: #000;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  color: #fff;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: #000;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #fff;
}
