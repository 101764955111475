@import '../Styles/mixins.scss';

@mixin pageContent {
  #sidebarNav,
  #hiddenSidebar {
    grid-area: sidebar;
    background-color: $darkGunmetal;
    .menu {
      margin: $sm $sm 0 $sm;
      height: $lg;
    }
    .logo {
      display: flex;
      justify-content: flex-start;
      margin: 1.25em 0 0 1.75em;
      height: $lg;
    }
    .hiddenMenu {
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin: 1.25em auto 0 auto;
      height: $xl;
    }
    .hiddenLogo {
      display: block;
      margin: 0 auto 0 auto;
      height: $lg;
    }
    .links {
      width: 100%;
      border: none;
      background: none;
      text-align: left;
      color: $bg-light;
      display: flex;
      align-items: center;
      margin: 0.5em 0;
      font-size: $rg + 0.1em;
      padding: 5px $sm 5px $md;
      &:hover {
        background: $dmGreen;
        cursor: pointer;
      }
    }
    .child-links {
      text-align: right;
      color: $bg-light;
      display: flex;
      align-items: center;
      margin: 0.25em 0;
      font-size: $rg + 0.15em;
      padding: 5px $sm 5px $xxl;
      &:hover {
        background: $dmGreen;
        cursor: pointer;
      }
    }
    .hidden-links {
      text-align: left;
      color: $bg-light;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1em 0;
      font-size: $rg + 0.15em;
      padding: 5px $sm 5px $sm;
      &:hover {
        background: $dmGreen;
        cursor: pointer;
      }
    }
    .hidden-text {
      text-align: center;
      color: $bg-light;
      display: flex;
      align-items: center;
      margin: 0.5em 0;
      font-size: $rg + 0.15em;
      font-size: 12px;
      &:hover {
        cursor: pointer;
      }
    }
    .is-active {
      background: $bg-light;
      color: $darkGunmetal;
      svg {
        fill: $darkGunmetal !important;
      }
      p {
        color: $darkGunmetal;
      }
      &:hover {
        background: $bg-light;
        cursor: default;
      }
    }
  }
  #hiddenSidebar {
    grid-area: sidebar;
    background-color: $darkGunmetal;
    width: 100%;
  }
  #contentContainer {
    grid-area: content;
    width: 100%;
    min-height: 100vh;
    padding: 0 $xl 0 $lg;
  }

  @media only screen and (max-width: 768px) {
    /* For mobile phones: */
    #sidebarNav,
    #hiddenSidebar {
      display: none;
    }

    #contentContainer {
      padding: 0 $md 0 $md;
    }

    grid-template-columns: 1fr 100%;
  }
}

#dashboardContainer {
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 85%;
  @include pageContent;
}

#hiddenSidebarDashboard {
  display: grid;
  grid-template-areas: 'sidebar content';
  grid-template-columns: 1fr 95%;
  @include pageContent;
}
