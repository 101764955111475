@import "./../Styles/mixins.scss";

$spacing: 50px;
$radius: 4px;
$date: 120px;
$dotborder: 4px;
$dot: 20px;
$line: 4px;

$InboundText: #ff482c;
$OutboundText: tint($InboundText, 20%);
$InboundCall: #ffb109;
$OutboundCall: tint($InboundCall, 20%);
$InboundEmail: #00c04a;
$OutboundEmail: tint($InboundEmail, 20%);
$InPersonMeeting: #2f9dd4;
$SMS: #2f48d4;
$Message: #2e3668;
$InternalNote: #292929;
$SystemNote: #111111;
$LeftVoicemail: #6319c4;
$Zendesk: #17494d;

.note-sms {
  background: $SMS;
}

.note-system {
  background: $SystemNote;
}

.note-message {
  background: $Message;
}

.note-inbound-text {
  background: $InboundText;
}

.note-outbound-text {
  background: $OutboundText;
}

.note-inbound-call {
  background: $InboundCall;
}

.note-outbound-call {
  background: $OutboundCall;
}

.note-inbound-email {
  background: $InboundEmail;
}

.note-outbound-email {
  background: $OutboundEmail;
}

.note-internal {
  background: $InternalNote;
}

.note-inperson-meeting {
  background: $InPersonMeeting;
}

.note-left-voicemail {
  background: $LeftVoicemail;
}

.zendesk-note {
  background: $Zendesk;
}

.note {
  text-align: center;
  margin: $xl * 2.5 0;
}

.note-pill {
  margin-top: $xs;
  padding: 4px 8px;
  @include borderRadius($xxxl);
  font-size: $rg - 0.1em;
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  color: white;
}

.timeline {
  border-left: 3px solid $nickel;
  margin: $spacing auto;
  line-height: $md - 0.3;
  font-size: $rg + 0.1;
  padding: $spacing;
  list-style: none;
  text-align: left;
  max-width: 67%;

  .notes-source {
    font-size: $md - 0.4em;
    color: $darkGunmetal;
  }

  .event {
    color: $label;
    padding-bottom: ($spacing * 0.5);
    margin-bottom: 15px;
    position: relative;

    .note-body {
      position: relative;
      font-style: italic;
      padding: $sm;
      margin-top: 25px;
      @include borderRadius($xs);
      background-color: $bg-shade;
      word-break: break-word;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 8%;
        width: 0;
        height: 0;
        border: 18px solid transparent;
        border-bottom-color: $bg-shade;
        border-top: 0;
        margin-left: -18px;
        margin-top: -18px;
      }
    }

    &:last-of-type {
      padding-bottom: 0;
      margin-bottom: 0;
      border: none;
    }

    &:before,
    &:after {
      position: absolute;
      display: block;
      top: 0;
    }

    &:before {
      left: ((($date * 0.6) + $spacing + $line + $dot + ($dotborder * 2)) * 1.5) *
        -1;
      content: attr(data-date);
      text-align: right;
      font-weight: 100;
      font-size: $rg;
      min-width: $date;
    }

    &:after {
      left: ($spacing + $line + ($dot * 0.35)) * -1;
      background: $bg-light;
      border: 3px solid $nickel;
      @include borderRadius($xxxl);
      height: $dot;
      width: $dot;
      content: "";
      top: 2.5px;
    }
  }
}
