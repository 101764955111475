.menu-icon {
  cursor: pointer;
  padding: 0.25em;
  background-color: #1e202b;
  border: none;
}

.mobile-navbar {
  background: #1e202b;
  height: 65px;
  flex: 0.3;
  align-items: center;
  display: flex;
}
.mobile-navbar .mobile-nav-logo {
  display: flex;
  align-self: center;
}

.btn-navbar {
  margin-top: 0.5em;
  background: #388640;
  text-align: center;
  width: 100%;
  font-size: 0.9em;
  padding: 0.75em;
  -webkit-border-radius: 1.5em;
  -moz-border-radius: 1.5em;
  -ms-border-radius: 1.5em;
  border-radius: 1.5em;
  border: none;
  cursor: pointer;
  border: 1px;
}

.navbar-menu-logout:hover {
  filter: brightness(120%);
}

.mobile-navbar-menu {
  background: #1e202b !important;
}
.mobile-navbar-menu .profile {
  color: #fff;
  text-align: center;
  cursor: pointer;
  padding-bottom: 0.75em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.03);
}
.mobile-navbar-menu p {
  color: #fff;
}
.mobile-navbar-menu a.links,
.mobile-navbar-menu div.links,
.mobile-navbar-menu button.links,
.mobile-navbar-menu button.profile {
  user-select: none;
  text-align: left;
  color: #f9f6f2 !important;
  display: flex;
  align-items: center;
  padding: 0.5em;
  font-size: 1.05em;
  background-color: #1e202b;
  border: none;
  color: #f9f6f2;
  font-size: 1.05em;
}
.mobile-navbar-menu a.links:hover,
.mobile-navbar-menu div.links:hover,
.mobile-navbar-menu button.links:hover,
.mobile-navbar-menu button.profile:hover {
  background: #388640;
}
.mobile-navbar-menu span:hover {
  background: #388640;
  cursor: pointer;
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}

