@import './../Styles/mixins.scss';
.head-navbar {
  margin-top: 10px !important;
  margin-left: 0 !important;
  padding-bottom: $md;
  .profile {
    width: 55px;
    height: 55px;
    @include borderRadius($xs);
    @include border(solid, 1px);
    color: $bg-light;
    font-size: $sm + 0.4em;
    white-space: nowrap;
    border: 1px solid $dmGreen;
    color: $dmGreen;
    background: $bg-light;
    padding: 1.1em !important;
    &:hover {
      cursor: pointer;
      background: $dmGreen;
      color: #fff;
    }
  }

  .balance {
    text-align: center;
  }

  .balance-amount {
    color: $nickel;
  }

  .icons {
    display: flex;
    max-width: 270px;
    justify-content: space-between;
    div {
      display: flex;
      margin: $xs;
      justify-content: space-between;
      cursor: pointer;
      svg {
        align-self: center;
        display: flex;
        align-content: center;
      }
      p {
        font-size: $rg;
        align-self: center;
      }
    }
  }
  .nav-form {
    align-items: center;
    display: flex;

    .search-box {
      -webkit-appearance: none;
      font-size: $rg;
      @include border(solid, 1px);
      @include borderRadius($xs);
      padding: $sm - 0.05em;
    }
    select {
      background-color: #fff;
      @include fontLato();
      margin: 0 10px;
      @include border(solid, 1px);
      @include borderRadius($xs);
      padding: $sm;
      height: $xxxl - 1.1em;
      color: $darkGunmetal;
      font-size: $rg;
    }
    // Firefox specific overrides
    @supports (-moz-appearance: none) {
      select {
        padding: 0 0 0 10px;
      }
    }
  }
  .two-factor {
    padding-top: 1.1em !important;
    padding-bottom: 1.1em !important;
  }
}

.dropdown {
  display: inline-block;
  display: flex;
  align-self: center;
}

.dropdown__content {
  display: none;
  position: absolute;
  background: $bg-light;
  @include borderRadius($xs);
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.5);
  width: 300px;
  min-height: 325px;
  height: 5vh;
  z-index: 9999;
  top: 55px;
  right: 0;
}

.dropdown--active .dropdown__content {
  display: block;
}

.menu {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  h2,
  p {
    color: $bg-light;
  }

  .menu-header {
    text-align: center;
    padding: $sm;
    border-top-left-radius: $xs;
    border-top-right-radius: $xs;
    background: $darkGunmetal;
    flex: 0.15;
    border-bottom: 2px solid lighten($darkGunmetal, 65%);
  }

  .menu-options {
    flex: 0.8;
    font-size: $rg + 0.2em;
    &.wallet {
      margin-top: -15px;
    }
    p {
      color: $darkGunmetal;
      padding: 0 $md;
    }
    .menu-item {
      padding: $xs $sm;
      display: flex;
      &:hover {
        background: $dmGreen;
        p {
          color: $bg-light;
        }
        svg {
          fill: $bg-light !important;
        }
      }
    }
  }

  .menu-logout {
    display: flex;
    width: 75%;
    flex: 0.075;
    justify-content: center;
    align-self: center;
    &:hover {
      background: lighten($dmGreen, 4%);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .head-navbar {
    .nav-form {
      select {
        padding: 0 0 0 5px;
        margin-left: 0;
      }
    }
  }
}
