@import './../Styles/mixins.scss';

#formContainer {
  min-height: 75vh;
}

.security {
  #formContainer {
    min-height: 0;
  }
}

#denyIDForm {
  margin-top: 3.5%;
}

.form-outline {
  // Overrides for particular forms ———————————————————————————— •
  &.form-create {
    @include border(solid, 1px);
    @include borderRadius($xs);
    margin-top: $xs;
    padding: $md;
    &.long-text {
      padding: $sm;
    }
  }

  input::placeholder,
  textarea::placeholder {
    color: rgba(0, 0, 0, 0.35);
  }

  .pills {
    display: flex;
    align-items: center;

    .form-pill {
      display: flex;
      align-items: center;
      margin: 0 1em;
      text-transform: uppercase;
      letter-spacing: 2pt;
      border-radius: 5px;
      font-weight: 700;
      font-size: 13px;
      padding: 0.2em 0.75em;
      .enabled {
        padding: 0;
        color: $dmGreen;
      }

      .disabled {
        padding: 0;
        color: $red;
      }
    }
  }

  div.icon {
    width: 10%;
    background: #272a39;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-bottom-left-radius: 0.33em;
    border-top-left-radius: 0.33em;
    border: 1px solid #272a39;
  }

  div.icon-input {
    background: #fff;
    display: flex;
    @include border(solid, 1px);
    justify-content: flex-end;
    align-items: center;
    border-radius: 0.33em;

    input {
      outline: none;
    }
  }

  div.icon-input-error {
    border: 1px solid $red;
    color: $red !important;
  }

  &.inline-edit-form {
    @include border(solid, 1px);
    @include borderRadius($xs);
    padding: $md $sm $sm $sm;
    margin-bottom: 15px;
    .columns {
      margin-bottom: $sm;
      &:last-child {
        margin-bottom: 0;
      }
      &:nth-child(odd) {
        background-color: $bg-shade;
      }
      .column {
        padding: 0;
      }
    }
    &.operating-states {
      padding-top: $sm;
    }
  }
  input[name='Wallet'] {
    width: 350px;
  }
  // removes bg
  &.editing {
    .form-row {
      padding: 0 $xs;

      > label {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
    .columns {
      &:nth-child(odd) {
        background-color: $bg-light;
      }
    }
  }
  .edit-actions-override {
    margin: 10px -12px 15px -12px;
  }
  // End of Overrides for particular forms ———————————————————————————— •

  .form-title {
    margin-bottom: $xs;
  }

  .form-padding {
    padding-bottom: $md + 0.2em;
  }

  .form-row {
    display: flex;
    margin: 5px 0;
    font-size: $sm + 0.222em;
    padding-left: 2px;
    padding-right: 2px;

    > label.expand {
      flex: 3 1;
    }

    > label {
      padding-left: 3px;
      flex: 1;
    }
    > input,
    select,
    p,
    .p-style,
    input[type='checkbox'],
    input[type='radio'] {
      flex: 2;
      margin: 0;
      word-break: break-all;
    }
    p.expand {
      flex: 1.5;
    }
    input[type='checkbox'] {
      margin-left: 15px;
    }
  }

  .justify {
    margin-top: 0.75em;
    display: flex;
    padding-left: $sm;
    label {
      align-self: center;
    }
    input[type='checkbox'] {
      margin: 0 42px;
    }
  }

  .hours {
    .form-row {
      .justify-icon {
        text-align: right;
        padding-right: $lg;
      }
    }

    input[type='checkbox'] {
      margin-top: 8px;
      flex: 1;
    }
  }

  .item-label {
    color: $label;
    font-size: $sm + 0.1em;
    font-weight: normal;
    @include fontLato();
    &.input-error {
      color: $red !important;
      border: none !important;
    }
  }

  .form-error {
    color: $red;
    border-color: $red;
  }

  .input-error {
    border: 1px solid $red;
  }

  input:disabled,
  select:disabled {
    background-color: $disabled;
    &:hover {
      cursor: not-allowed;
    }
  }
  // overrides for DualListBox
  #listBox {
    margin-bottom: $md;
    .react-dual-listbox {
      .rdl-control {
        height: $xxxl + 14em;
      }
      select optgroup option {
        &:checked {
          background-color: $darkGunmetal;
          color: $bg-light;
        }
        &:hover {
          background-color: $dmGreen;
          color: $bg-light;
          cursor: pointer;
        }
      }
    }
  }

  #addPhoneBtn {
    margin-top: 10px;
    display: block !important;
    width: 26%;
  }

  input[type='checkbox'] {
    height: $md;
    padding: $rg + 0.25em 0 $rg + 0.25em 5px;
  }

  input,
  select,
  textarea {
    @include fontLato();
    background-color: #fff;
    @include borderRadius($xs);
    @include border(solid, 1px);
    width: 100%;
    height: 2.5em;
    padding-left: $sm;
    color: $darkGunmetal;
    font-size: $rg;
    overflow: hidden;
  }

  textarea {
    min-height: 175px;
    padding: $sm;
  }

  select {
    height: $md + 1em;
    padding: 0 $rg + 0.25em;
    option {
      font-size: $rg;
    }
  }

  ::placeholder {
    color: $darkGunmetal;
    font-size: $rg;
  }

  .cancel-link {
    padding-left: 15px;
    color: $red;
    &:hover {
      text-decoration: underline;
    }
  }

  .checkbox-column {
    display: flex;
  }

  .inline-checkbox {
    display: flex;
    align-items: center;
    width: 100%;
    .item-label {
      width: 50%;
    }
  }
}

// react-dual-box scrolling
.form-outline #listBox .react-dual-listbox .rdl-control {
  height: 18em;
  overflow: scroll;
}
