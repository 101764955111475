@import './../Styles/mixins.scss';

.menu-icon {
  cursor: pointer;
  padding: 0.25em;
  background-color: $darkGunmetal;
  border: none;
}

.mobile-navbar {
  background: $darkGunmetal;
  height: 65px;
  flex: 0.3;
  align-items: center;
  display: flex;

  .mobile-nav-logo {
    display: flex;
    align-self: center;
  }
}

.btn-navbar {
  margin-top: 0.5em;
  background: $dmGreen;
  text-align: center;
  width: 100%;
  font-size: $rg;
  padding: 0.75em;
  @include borderRadius($md);
  border: none;
  cursor: pointer;
  border: 1px;
}

.navbar-menu-logout:hover {
  filter: brightness(120%);
}

.mobile-navbar-menu {
  background: $darkGunmetal !important;

  .profile {
    color: #fff;
    text-align: center;
    cursor: pointer;
    padding-bottom: $sm;
    border-bottom: 1px solid rgba(255, 255, 255, 0.03);
  }

  p {
    color: #fff;
  }

  a.links,
  div.links,
  button.links,
  button.profile {
    user-select: none;
    text-align: left;
    color: $bg-light !important;
    display: flex;
    align-items: center;
    padding: 0.5em;
    font-size: $rg + 0.15em;
    background-color: $darkGunmetal;
    border: none;
    color: $bg-light;
    font-size: $rg + 0.15em;
    &:hover {
      background: $dmGreen;
    }
  }

  span:hover {
    background: $dmGreen;
    cursor: pointer;
  }
}

.menu-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
}
