@import "./../Styles/mixins.scss";

.dropdown-container {
  position: relative;
}
.dropdown-header {
  text-align: center;
  padding: $sm;
  border-top-left-radius: $xs;
  border-top-right-radius: $xs;
  color: $bg-light;
  background: $darkGunmetal;
  flex: 0.15;
  border-bottom: 2px solid lighten($darkGunmetal, 65%);
}

.dropdown-content {
  background: #f9f6f2;
  border-radius: 0.5em;
  -webkit-border-radius: 0.5em;
  -moz-border-radius: 0.5em;
  -ms-border-radius: 0.5em;
  position: absolute;
  top: 60px;
  right: 0.75em;
  width: 625px;
  box-shadow: 0px 0px 19px -4px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 4;

  &.small {
    width: 275px;
  }

  .btn {
    margin: 0.5em !important;
  }

  &.active {
    padding: 0px;
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    border-bottom: 1px solid #dddddd;
  }

  .dropdown-content li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
  }

  @media only screen and (max-width: 768px) {
    width: 325px
  }
}

.dropdown-trigger {
  border-radius: 90px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 0.8em !important;
  border: none;
  vertical-align: middle;

  :hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }

  span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
  }

  img {
    border-radius: 90px;
  }
}

.dropdown-btn {
  margin-left: 10px !important;
}

.column-scroll {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 1em;

  .column-option {
    margin-left: 10px;
  }
}