@import './../../../Styles/mixins.scss';

.loading-container {
  height: 75vh;
  display: flex;

  &.animate {
    animation: hidden 250ms;
  }

  &.overlay {
    background: $bg-light;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
    height: 100vh;
    width: 100vw;
  }
}

.logo-path-1 {
  fill: $darkGunmetal;
}

.logo-path-2 {
  fill: $darkGunmetal;
}

.digitalmint-logo {
  height: $xl - $xs;
  width: $xl - $xs;
  margin: auto;
  display: block;
}

.digitalmint-logo {
  animation: rotation 750ms infinite linear;
}

@keyframes rotation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(359deg);
  }
}

@keyframes hidden {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
