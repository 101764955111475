@import "./../Styles/mixins.scss";

.dropzone-img {
  height: 260px;
  @include borderRadius($xs);
}

.disable-dropzone {
  filter: opacity(0.1) brightness(80%);
}

.no-img {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-color: black;
  border-style: dotted;
  border-radius: 10px;
}

.upload {
  width: 400px;
  margin: 20px 25px 10px 25px;
}

section {
  div.dropzone-container {
    height: 275px;
    width: 425px;
    @include border(dotted, 2px);
    @include borderRadius($xs);
  }

  div.dropzone-container:hover {
    border-color: $dmGreen;
    cursor: pointer;
    filter: contrast(110%);
  }

  .dropzone-container {
    display: flex;
    margin: auto;
  }
  div.display {
    display: flex;
    justify-content: center;
    width: inherit;
    height: inherit;
    align-items: center;
    text-align: center;
  }

  .lds-dual-ring {
    position: absolute;
    display: inline-block;
    z-index: 10;
    width: 64px;
    height: 64px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    @include borderRadius(50%);
    border: 5px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
