@import 'bulma/bulma.sass';
@import './../Styles/mixins.scss';

.auth-container {
  max-width: 500px;
  margin: 0 auto;

  padding: $lg 0;
  .logo {
    width: 20vw;
    display: block;
    margin: auto;
  }

  .version-num {
    color: $label;
    margin-top: $sm;
  }

  @keyframes animateOTP {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animateOTP {
    -webkit-animation-name: animateOTP;
    animation-name: animateOTP;
  }

  .animated {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  @media only screen and (max-width: 768px) {
    .logo {
      width: 75vw;
      display: block;
      margin: auto;
    }
  }
}
