@import "../../Styles/mixins.scss";

.alert-container {
  margin-bottom: 15px;
}

#narrative {
  margin-top: 10px;
  padding: 20px;
  @include border(solid, 1px);
  @include borderRadius($xs);

  .narrative {
    padding: 20px;
    font-style: italic;
  }
}
