@import '../../Styles/mixins.scss';

.form-input-component {
  label.item-label {
    color: $label;
    display: inline-block;
    font-size: $sm + 0.1em;
    font-weight: normal;

    &.input-error {
      color: $red;
    }
  }

  .form-error {
    border-color: $red;
    color: $red;
    display: inline-block;
    font-size: $sm + 0.1em;
    margin-left: 4px;
  }

  input,
  select {
    &.input-error {
      color: $red;
      border: 1px solid $red;
    }
  }

  &:disabled {
    background-color: $disabled;

    &:hover {
      cursor: not-allowed;
    }
  }
}
