@import './../Styles/mixins.scss';

#siteContainer {
  .btn {
    @include ctaBtn(15px, 0, 0);
    margin: $sm 0;

    padding: $sm - 0.07 $md;
    text-align: center;
    color: #fff;
    &.btn-icon {
      padding: ($sm - 0.2em) $sm;
    }
    &.primary-btn {
      background: $dmGreen;
      &:hover {
        background: lighten($dmGreen, 4%);
      }
      &:disabled {
        background: $disabled;
        cursor: not-allowed;
      }
      &.disabled-link {
        background: $disabled;
        cursor: not-allowed;
      }
    }
    &.orange {
      background: $warning;
      &:hover {
        background: lighten($warning, 4%);
      }
    }
    &.secondary-btn-outline {
      @include iconBtn($nickel);
      &.action {
        background: none;
      }
    }
    &.primary-btn-outline {
      @include iconBtn($dmGreen);
    }
    &.red-btn-outline {
      @include iconBtn($red);
    }
    &.orange-btn-outline {
      @include iconBtn($warning);
    }
    &.red {
      background: $red;
      &:hover {
        background: lighten($red, 4%);
      }
    }
    &.blue {
      border: 1px solid #006994;
      color: #006994;
      &:hover {
        background: #006994;
        color: #fff;
      }
    }

    &.is-pulled-right {
      margin-top: 4px;
    }
  }

  #filterBtn {
    margin-top: 19px;
  }

  .btn-icons {
    padding: 0 $md;
    margin-bottom: 0;

    .btn {
      margin-left: 4px;
      margin-right: 4px;
      transform: scale(0.95);
    }
  }

  .subpoena-activity {
    margin: 2px;
    padding: 4px 6px;
  }

  .edit-actions {
    margin-bottom: 0px;
    .edit-btn {
      padding: 4px 8px;
    }

    .offset {
      margin-left: 6px;
    }

    .copy-btn {
      padding: 4px;
    }

    .add-btn {
      padding: 4px 10px;
    }

    .cancel-btn {
      padding: 2px 10px 6px 10px;
    }

    .disable-edit-btn {
      display: none;
    }
  }

  .go-back-link {
    color: $dmGreen;
    margin-bottom: $md;

    span:hover {
      cursor: pointer;
    }

    button {
      background: none;
      border: none;
      color: $dmGreen;
    }
  }

  .toggle-button {
    background: $dmGreen;
    @include border(solid, 1px);
    @include borderRadius($xs);
    cursor: pointer;
    margin: auto;
    display: flex;
    width: 35px;
    align-self: center;
    justify-content: center;
  }

  .clean-btn {
    background: none;
    border: none;
    cursor: pointer;
  }

  .pill {
    @include borderRadius($xxxl);
    padding: 6px 12px;
    color: #fff;
    box-shadow: none;
    &.ban {
      background-color: #e71d36;
    }
    &.blocking {
      background-color: #ee6274;
    }
    &.status-unapproved {
      background-color: $yellow;
      color: $darkGunmetal;
    }

    &.status-tier1 {
      background: shade($dmGreen, 50%);
    }

    &.status-tier2 {
      background: shade($dmGreen, 40%);
    }

    &.status-tier3 {
      background: shade($dmGreen, 20%);
    }
  }
}
