@import "./mixins.scss";

.table-container {
  @include borderRadius($xs);
  @include border(solid, 1px);
  .table-head {
    background-color: $darkGunmetal;
    text-align: center;
    color: $bg-light;
    padding: $xs;
  }

  .table {
    display: table;
    font-size: $rg;
    width: 100%;
    background: $bg-light;

    .table-body {
      display: table-row-group;
    }
    .table-row {
      display: flex;
      &:nth-child(even) {
        background-color: $bg-light;
      }
      &:nth-child(odd) {
        background-color: $bg-shade;
      }
    }
    .table-cell {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-self: center;
      padding: 3px $md;
      color: $label;
    }

    .cell-label-right {
      text-align: right;
      color: $darkGunmetal;
      border: none;
      max-width: inherit;
      padding: 0, $xs, 0, 0;
      height: 100%;
      margin: auto;
      display: flex;
      justify-content: flex-end;
    }

    .break-cell {
      word-break: break-all;
    }
    .cell-label {
      font-size: $rg;
      flex: 1;
      vertical-align: middle;
    }
  }
}
