@import "./../Styles/mixins.scss";

.no-match {
  height: 75vh;
  margin: $sm;
  justify-content: center;
  align-items: center;

  h1 {
    font-size: $xl - 0.2em;
    color: $darkGunmetal;
    text-align: center;
    font-weight: 600;
  }

  .error-logo {
    height: $xxxl + $sm;
    width: $xxxl + $sm;
    margin: auto;
    display: flex;
    fill: $darkGunmetal;
  }

  .cls-2 {
    animation: break 750ms;
    animation-fill-mode: forwards;
  }

  span {
    color: $bg-shade;
  }

  hr {
    @include border(solid, 1px);
    height: 1px;
  }

  .redirect-links {
    display: flex;
    justify-content: space-evenly;
    font-size: $rg;
  }

  @keyframes break {
    from {
      transform: translate(0px) rotate(0deg);
    }
    to {
      transform: translate(15px) rotate(13deg);
    }
  }
}
