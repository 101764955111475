@import "./mixins.scss";

.tabs-container {
  margin-top: $xl;
  &.auth-tabs {
    @include border(solid, 1px);
    padding: 12px;
    @include borderRadius($xs);
  }
  &.nested-tabs {
    margin-top: 10px;
    .tab-list {
      margin-bottom: $sm;
    }
  }
  .tab-list {
    margin-bottom: $md;
    font-size: $md - 0.2em;
  }

  .tab {
    @include border(solid, 0 0 1px 0);
    text-align: center;
    color: $nickel;
    font-size: 0.8em;
    &:hover {
      cursor: pointer;
      background: $nickel;
      border-top-left-radius: $xs;
      border-top-right-radius: $xs;
      color: $bg-light;
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .btn {
    &.is-pulled-right {
      margin-top: 7px !important;
    }
  }

  .react-tabs__tab--selected {
    color: $bg-light;
    background: $darkGunmetal;
    border-top-left-radius: $xs;
    border-top-right-radius: $xs;
    &:hover {
      background: $darkGunmetal;
      cursor: default;
    }
  }
}

@media only screen and (max-width: 768px) {
  .tab-list {
    margin-bottom: $md;
    font-size: $md - 0.4em !important;
  }
}
