@import '../Styles/mixins.scss';
@import '../Styles/react-table.css';

// Base Colors
$white: #fff;

// Events
$Activated: $dmGreen;
$Available: $dmGreen;
$CashDeposited: $dmGreen;
$PowerOn: $dmGreen;
$CashCollected: shade($dmGreen, 10%);
$Deactivated: $red;
$Unavailable: $red;
$KioskLocked: $red;
$PowerOff: $red;
$Reboot: $yellow;
$MaintenanceModeEntered: shade($yellow, 10%);
$MaintenanceModeExited: shade($yellow, 20%);
$Created: #64a7ff;
$CashCasetteRemoved: #7821f2;
$CashCasetteInserted: #6829e6;
$TransactionInitiated: shade(#6829e6, 20%);
$TransactionExecuted: shade(#7821f2, 20%);
$EventCreated: #4553b1;
$EventDecommissioned: #4b66a0;
$EventActivated: #4d708e;

// Trade Type
$Bitcoin: #ff9900;
$Litecoin: #006097;
$Ethereum: #3c3c3d;
$BitcoinCash: #ee8c28;

$TestBitcoin: tint($Bitcoin, 45%);
$TestLitecoin: tint($Litecoin, 45%);
$TestEthereum: tint($Ethereum, 45%);
$TestBitcoinCash: tint($BitcoinCash, 45%);

// Severity Pill
$Info: tint($dmGreen, 40%);
$Warning: $yellow;
$Error: $red;
$Critical: tint($red, 20%);

// Platforms
// Based off brand colors of respective companies
$Genmega: #0571b0;
$Hyosung: #2b4599;
$Slabb: #4c96be;
$Kiosk: #afbd21;
$Ipad: #333333;
$Api: #4b66a0;
$Web: #4d708e;
$MayaTech: #50468d;
$Futurakiosk: #361e2e;
$Easy2u: #a83232;
$ePay: #015696;

// Approvals
$NeedsID: $neutral;
$ID: shade($warning, 20%);
$FullID: $red;
$OFAC: $red;
$TPIV: $red;
$Over55: $yellow;
$Tier2Review: tint(#6700ae, 20%);
$PlaidLightning: #abffdb;
$SelfieCheck: $yellow;
$DocumentVerification: $red;
$PlaidRiskCheck: #ffffff;

// Documents
$Transactions: $nickel;
$SupportingDocs: #64a7ff;
$PDF: #6700ae;
$SubpoenaRequest: #86d43d99;
$SubpoenaSigned: #a43dd4;
$BlockchainForensics: $Ipad;
$OtherDoc: $darkGunmetal;
$AlphaBlack75: rgba(0, 0, 0, 0.75);
$Gray700: #595959;
$Gray800: #383838;

// TransactionState
$TransactionState100: #4d708e;
$TransactionState200: $darkGunmetal;
$TransactionState300: #64a7ff;
$TransactionState310: #0993b6;

$TransactionState400: $yellow;
$TransactionState410: tint($dmGreen, 20%);
$TransactionState420: shade($yellow, 20%);
$TransactionState500: $red;

$Open: $dmGreen;
$Close: $red;
$Pending: $yellow;

// Disposition
$CNS: $white;
$CNSSTR: #4d708e;
$SARFiled: $yellow;
$Escalated: #ff6c0a;
$FalsePositive: $dmGreen;
$NinetyDayReview: $blue;
$DataQuality: $neutral;

// Alert
$volume: #004ea9;
$weekly: #5b00d7;
$monthly: #0050bd;
$spike: #9e0069;
$excessive: #870032;

// Commission
$gross: $dmGreen;
$cash: tint($dmGreen, 40%);
$fiat: #0050bd;

// default:
$tx-unknown: #000;

.expired {
  color: rgb(238, 90, 90);
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}

.priority {
  width: to;
  display: flex;
  justify-content: center;
}

.priority-pill {
  height: 15px;
  width: 15px;
  margin: 0 0.1em;
  border-radius: 1em;
  background: rgba(0, 0, 0, 0.1);
}

.high {
  background: rgb(238, 90, 90);
}

.netrual {
  background: rgb(233, 144, 42);
}

.low {
  background: rgb(103, 172, 112);
}

.table-actions {
  margin: 0;
  vertical-align: middle;
  h1 {
    margin: 0;
  }
}

.link {
  color: inherit !important;
}

.link:hover {
  background: none !important;
  filter: brightness(125%);
}

// Pills
.table-pill {
  color: $white;
  padding: 2px 6px;
  margin: 0 2px;
  @include borderRadius($xxxl);
  word-wrap: break-word;
}

.disposition-cns {
  background: $CNS;
  color: $darkGunmetal;
}

.disposition-sar-filed {
  background: $SARFiled;
  color: $darkGunmetal;
}
.disposition-str {
  background: $CNSSTR;
}

.investigation-escalation {
  background: $Escalated;
}

.investigation-follow-up {
  background: $SARFiled;
  color: $darkGunmetal;
}

.investigation-referral {
  background: $NinetyDayReview;
}

.disposition-90-day {
  background: $NinetyDayReview;
}

.disposition-escalated {
  background: $Escalated;
}

.disposition-false-positive {
  background: $FalsePositive;
}

.disposition-data-quality {
  background: $DataQuality;
}

.disposition-apollo {
  background: $darkGunmetal;
}

.severity-info {
  background: $Info;
}

.severity-warn {
  background: $Warning;
  color: $darkGunmetal;
}

.severity-error {
  background: $Error;
}

.severity-critical {
  background: $Critical;
}

.created {
  background: $EventCreated;
  color: $darkGunmetal;
}

.decommissioned {
  background: $EventDecommissioned;
  color: $darkGunmetal;
}

.activated {
  background: $EventActivated;
  color: $darkGunmetal;
}

.installed {
  background: $blue;
}

.event-mode-entered {
  background: $MaintenanceModeEntered;
  color: $darkGunmetal;
}

.event-mode-exited {
  background: $MaintenanceModeExited;
  color: $darkGunmetal;
}

.event-cash-collected {
  background: $CashCollected;
}

.event-kiosk-locked {
  background: $KioskLocked;
}

.event-power-off {
  background: $PowerOff;
}

.event-power-on {
  background: $PowerOn;
}

.event-reboot {
  background: $Reboot;
  color: $darkGunmetal;
}

.event-unavailable {
  background: $Unavailable;
}

.event-available {
  background: $dmGreen;
}

.event-cash-deposited {
  background: $CashDeposited;
}

.event-cash-casette-removed {
  background: $CashCasetteRemoved;
}

.event-cash-casette-inserted {
  background: $CashCasetteInserted;
}

.event-transaction-initiated {
  background: $TransactionInitiated;
}

.event-transaction-executed {
  background: $TransactionExecuted;
}

.event-created {
  background: $Created;
}

.event-activated {
  background: $Activated;
}

.event-deactivated {
  background: $Deactivated;
}

.alert-closed {
  background: $Close;
}

.alert-open {
  background: $Open;
}

.alert-pending {
  background: $Pending;
  color: $darkGunmetal;
}

.document-transactions {
  background: $Transactions;
}

.document-signed-pdf {
  background: $PDF;
}

.document-unsigned-pdf {
  color: $PDF;
  background-color: $white;
}

.document-customer-info {
  background: $dmGreen;
}

.document-transaction-info {
  background: $Transactions;
}

.document-subpoena-request {
  background: $SubpoenaRequest;
}

.document-signed-subpoena {
  color: $white;
  background: $SubpoenaSigned;
}

.document-blockchain-forensics {
  background: $BlockchainForensics;
}

.document-other {
  color: $white;
  background: $OtherDoc;
}
.document-invoices {
  color: $white;
  background: $Gray800;
}
.document-legal {
  color: $white;
  background: $AlphaBlack75;
}
.document-signed {
  color: $white;
  background: $Gray700;
}

.document-supporting-docs {
  background: $SupportingDocs;
}

.status-unapproved {
  background: $yellow;
  color: $darkGunmetal;
}

.status-tier1 {
  background: shade($dmGreen, 50%);
}

.status-tier2 {
  background: shade($dmGreen, 40%);
}

.status-tier3 {
  background: shade($dmGreen, 20%);
}

.status-svc-account {
  background: #5b30da;
}

.status-banned {
  background: $red;
}

.status-employee {
  background: $dmGreen;
}

.trade-btcusd {
  background: $Bitcoin;
}

.trade-ltcusd {
  background: $Litecoin;
}

.trade-ethusd {
  background: $Ethereum;
}

.trade-bchusd {
  background: $BitcoinCash;
}

.trade-test-btcusd {
  background: $TestBitcoin;
}

.trade-test-ltcusd {
  background: $TestLitecoin;
}

.trade-test-ethusd {
  background: $TestEthereum;
}

.trade-test-bchusd {
  background: $TestBitcoinCash;
}

.name-genmega {
  background: $Genmega;
}

.name-hyosung {
  background: $Hyosung;
}

.name-slabb {
  background: $Slabb;
}

.name-ipad {
  background: $Ipad;
}
.name-ipad-legacy {
  background: tint($Ipad, 20%);
}
.name-ipad-bravo {
  background: tint($Ipad, 40%);
}
.name-ipad-customer-staging {
  background: tint($Ipad, 60%);
}
.name-ipad-teller-staging {
  background: tint($Ipad, 80%);
}

.name-api {
  background: $Api;
}

.name-api-dcs {
  background: tint($Api, 20%);
}

.name-api-tier3 {
  background: shade($Api, 20%);
}

.name-payment-proccessor {
  background: $ePay;
}

.name-api-secure {
  background: shade($Api, 40%);
}

.name-api-unknown {
  background: tint($Api, 40%);
}

.name-kiosk {
  background: $Kiosk;
  color: $white;
}

.name-web {
  background: $Web;
}

.model-unknown {
  background: $Web;
  color: $darkGunmetal;
}

.unknown {
  background: $disabled;
  color: $darkGunmetal;
}

.model-air-2 {
  background: tint($Ipad, 10%);
}

.model-kiosk {
  background: tint($Kiosk, 10%);
}

.model-slabb {
  background: shade($Slabb, 10%);
}

.model-hyosung {
  background: shade($Hyosung, 0%);
}

.model-genmega-xl {
  background: shade($Genmega, 0%);
}

.model-genmega-1 {
  background: shade($Genmega, 15%);
}

.model-genmega-2 {
  background: shade($Genmega, 30%);
}

.model-genmega-uk {
  background: shade($Genmega, 45%);
}

.model-genmega-cdu {
  background: shade($Genmega, 60%);
}

.model-genmega-card {
  background: shade($Genmega, 75%);
}

.model-genmega-sidecar {
  background: tint($Genmega, 15%);
}

.model-maya-tech {
  background: $MayaTech;
}

.model-easy2u {
  background: $Easy2u;
}

.model-futurakiosk {
  background: $Futurakiosk;
}

.status-500 {
  background: $TransactionState500;
}

.status-400 {
  background: $TransactionState400;
  color: $darkGunmetal;
}

.status-410 {
  background: $TransactionState410;
}

.status-420 {
  background: $TransactionState420;
}

.status-300 {
  background: $TransactionState300;
}

.status-310 {
  background: $TransactionState310;
}

.status-200 {
  background: $TransactionState200;
}

.status-100 {
  background: $TransactionState100;
}

.approval-id {
  background: $NeedsID;
}

.approval-full-id {
  background: $FullID;
}

.approval-tpiv {
  background: $TPIV;
}

.approval-tier2-review {
  background: $Tier2Review;
}

.approval-ofac {
  background: $OFAC;
}

.approval-over55 {
  background: $Over55;
  color: $darkGunmetal;
}

.approval-plaid-lightning {
  background: $PlaidLightning;
  color: $darkGunmetal;
}

.approval-selfie {
  background: $SelfieCheck;
  color: $darkGunmetal;
}

.approval-document-verify {
  background: $DocumentVerification;
}

.approval-plaid-risk {
  background: $PlaidRiskCheck;
  color: $darkGunmetal;
}

.approval-ssn {
  background: shade($TPIV, 15%);
}

.alert-volume {
  background: $volume;
}

.alert-weekly {
  background: $weekly;
}

.alert-monthly {
  background: $monthly;
}

.alert-spike {
  background: $spike;
}

.alert-excessive {
  background: $excessive;
}

.alert-otc-cash {
  background: tint($excessive, 30%);
}

.alert-otc-10k {
  background: shade($excessive, 15%);
}

.tx-unknown {
  background: $tx-unknown;
}

.ipad {
  content: url('./../images/apple.svg');
  font-size: 10px;
  height: 15px;
  padding-right: $xs;
}

.genmega {
  content: url('./../images/genmega.svg');
  font-size: 10px;
  height: 15px;
  padding-right: $xs;
}

.platform-pill {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gross {
  background-color: $gross;
}

.cash {
  background-color: $cash;
}

.fiat {
  background-color: $fiat;
}

.ReactTable {
  @include borderRadius($xs);
  text-align: center;
  font-size: $sm;
  @include fontLato();
  .rt-thead.-header {
    font-weight: bold;
    color: $label;
  }

  div.rt-tr {
    align-items: center;
  }

  .rt-td {
    align-self: center;
  }

  a {
    font-weight: bold;
    &:hover {
      background: $dmGreen;
      color: $bg-light !important;
      @include borderRadius($xs);
    }
  }

  .column-btn {
    color: $white;
    @include border(solid, 1px);
    @include borderRadius($xs);
    padding: 7px;
    margin: 0 0.25em;
    &:hover {
      cursor: pointer;
    }
    &.remove {
      background: $red;
    }
    &.create {
      background: $dmGreen;
    }
  }

  .rt-tbody .rt-td {
    white-space: unset;
    border-right: 1px solid rgba(0, 0, 0, 0.02);
  }

  .rt-noData {
    @include borderRadius($xs);
    background-color: rgba(0, 0, 0, 0.5) !important;
    color: #f9f6f2 !important;
  }
  .rt-thead.-headerGroups {
    display: none;
  }
  .rt-tr-group {
    font-size: $sm + 0.2em;
  }
  .-pagination {
    box-shadow: none;
    background: none;
    border: none;
    .-previous,
    .-next {
      button:enabled {
        background-color: $dmGreen;
      }
    }
    .-btn[disabled] {
      background: $disabled;
      opacity: 1;
    }
  }
  .rt-tr-group {
    display: inline-flex;
    .rt-tr.-odd {
      background-color: $bg-shade !important;
    }
  }
  .-btn {
    padding: 10px !important;
    border-radius: $xs !important;

    &:hover {
      background: lighten($dmGreen, 4%) !important;
    }

    &:disabled {
      &:hover {
        background: $disabled !important;
      }
    }
  }

  // Hack for Safari selects
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      select {
        all: initial;
      }
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $dmGreen;
}

input:focus + .slider {
  box-shadow: 0 0 1px $dmGreen;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.asc {
  border-top: 2px solid #009245;
}

.desc {
  border-bottom: 2px solid #009245;
}

.noData {
  margin: 8em;
}

.resizer {
  display: inline-block;
  width: 0.5em;
  height: to;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  touch-action: none;
  opacity: 0;
}

.fade-out {
  animation: fadeOut 0.2s;
  -webkit-animation: fadeOut 0.2s;
  -moz-animation: fadeOut 0.2s;
  -o-animation: fadeOut 0.2s;
  -ms-animation: fadeOut 0.2s;
}

.fade-in {
  animation: fadeIn 0.2s;
  -webkit-animation: fadeIn 0.2s;
  -moz-animation: fadeIn 0.2s;
  -o-animation: fadeIn 0.2s;
  -ms-animation: fadeIn 0.2s;
  z-index: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@-moz-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@-o-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}

@-ms-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.2;
  }
}
