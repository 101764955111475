// Light Colors ———————————————————————————— •
$bg-light: #f9f6f2;
$dmGreen: #388640;
$darkGunmetal: #1e202b;
$nickel: #6e7071;
$bg-shade: rgba(0, 0, 0, 0.1);
$borderColor: #ced1dd;
$label: $nickel;
$red: #e71d36;
$warning: #ff6c0a;
$yellow: #fff44f;
$neutral: #545454;
$blue: #384fb9;
$disabled: lighten($nickel, 40%);
$purple: #5b30da;

// Sizes ———————————————————————————— •
$xs: 0.5em;
$sm: 0.75em;
$rg: 0.9em;
$md: 1.5em;
$lg: 2.25em;
$xl: 3em;
$xxl: 3.5em;
$xxxl: 4em;

// Mixins ———————————————————————————— •
@mixin fontLato {
  font-family: "Lato", sans-serif;
}

@mixin border($style, $sides...) {
  border-color: $borderColor;
  border-style: $style;
  // top, right, bottom, left
  border-width: $sides;
}

@mixin borderRadius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

// How to use in other .scss files
@mixin ctaBtn($font-size, $padding, $margin-bottom-px) {
  @include borderRadius(10px);
  border: none;
  font-size: $font-size;
  padding: $padding;
  margin-bottom: $margin-bottom-px;
  &:hover {
    cursor: pointer;
  }
}

@mixin iconBtn($color) {
  border: 1px solid $color;
  background: transparent !important;
  color: $color;
  .icon {
    fill: $color;
  }
  &:hover {
    background: $color !important;
    color: #fff;
    .icon {
      fill: #fff;
    }
  }
}

// Responsive ———————————————————————————— •
@mixin responsive-max-break($media) {
  @media only screen and (max-width: $media) {
    @content;
  }
}

@mixin responsive-min-break($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

@function tint($color, $percentage) {
  @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(#000, $color, $percentage);
}
