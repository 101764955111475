@import "./../Styles/mixins.scss";

#textBlast {
  margin-bottom: $lg - 0.2em;
  .selected-filter {
    background: $darkGunmetal;
    padding: $sm - 0.4em;
    text-align: center;
    color: #fff;
    @include borderRadius($xxxl);
    &:hover {
      cursor: pointer;
      text-decoration: line-through;
      background-color: $red;
      color: #fff;
    }
  }
}
